import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  TextJustify,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown'
import config from '../website-config';
import { Link } from 'gatsby';
import _ from 'lodash';
import Obfuscate from 'react-obfuscate';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: ${colors.bodyBackground} //#fff;
  }

  // @media (prefers-color-scheme: dark) {
  //   .site-main {
  //     /* background: var(--darkmode); */
  //     background: ${colors.darkmode};
  //   }
  // }
`;

interface StrapiProps {
  location: Location;
  // pageContext: {
  //   currentPage: number;
  //   numPages: number;
  // };
  data: {
    allStrapiCourse: {
      nodes: Array<{
        name: string
        subtitle?: string
        // description: string
        teachers: Array<Teacher>
      }>

    }
  };
}
interface Teacher {
  name: string
}

const pageTitle = 'I Corsi'
const pageExcerpt = 'La nostra Scuola di Musica offre, per l\'anno scolastico 2024/2025, i seguenti corsi di musica...'

const renderTeachers = (teachers: Array<Teacher>) => {
  if (teachers.length == 1) {
    return (
      <p style={{fontSize: '1em', lineHeight: '1em'}}>
        docente: <Link to={`/teacher/${_.kebabCase(teachers[0].name)}/`}>{teachers[0].name}</Link>
      </p>
    )
  }
  else if (teachers.length > 1) {
    return (
      <p style={{fontSize: '1em', lineHeight: '1em'}}>
        DOCENTI: {
          <>
          <Link to={`/teacher/${_.kebabCase(teachers[0].name)}/`}>{teachers[0].name}</Link>
          {
            teachers.slice(1).map(teacher => (
              <span key={teacher.name}>
                , <Link to={`/teacher/${_.kebabCase(teacher.name)}/`}>{teacher.name}</Link>
              </span>
            ))
          }
          </>
        }
      </p>
    )
  }
}

const Courses: React.FC<StrapiProps> = props => (
  <IndexLayout>
    <Helmet>
      <html lang={config.lang} />
      <title>{pageTitle} - {config.title}</title>
      <meta name="description" content={pageExcerpt} />
      <meta property="og:site_name" content={config.title} />
      <meta property="og:title" content={`${pageTitle} - ${config.title}`} />
      <meta property="og:description" content={pageExcerpt} />
      <meta property="og:url" content={config.siteUrl + props.location.pathname} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${pageTitle} - ${config.title}`} />
      <meta name="twitter:description" content={pageExcerpt} />
      <meta name="twitter:url" content={config.siteUrl + props.location.pathname} />
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">{pageTitle}</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <Row>
                  <Col lg={7}>
                    <p>La nostra Scuola di Musica offre, per l'anno scolastico 2024/2025, i seguenti corsi di musica:</p>
                    {
                      props.data.allStrapiCourse.nodes.map(node => (
                        <div key={node.name} style={{marginBottom: '32px'}}>
                          <div style={{marginBottom: '8px'}}  >
                            <Link to={`/course/${_.kebabCase(node.name)}/`} style={{boxShadow: 'none'}}>
                              <h2 style={{ display: 'inline', marginBottom: '4px',fontSize: '1.5em', lineHeight: '0.8em', color: `${colors.bandaBlue}`}}>
                                {node.name}
                              </h2>
                              <FontAwesomeIcon style={{ display: 'inline', marginBottom: '8px', marginLeft: '4px'}} icon={faInfoCircle} size="xs" color={colors.bandaBlue}/>
                              {node.subtitle &&
                                <h4 style={{marginTop: '0', fontSize: '0.8em', lineHeight: '1.2em', color: `${colors.bandaBlue}`}}>{node.subtitle}</h4>
                              }
                            </Link>
                          </div>
                          {renderTeachers(node.teachers)}
                          {/* <ReactMarkdown css={TextJustify}>{node.description}</ReactMarkdown> */}
                        </div>
                      ))
                    }

                  </Col>
                  <Col lg={5}>
                    <section style={{textAlign: 'right'}}>
                      <p style={{fontSize: '1.6rem', lineHeight: '2.8rem'}}>
                        <b>Per informazioni e iscrizioni:</b><br/>
                        📌&nbsp;via Vittorio Veneto 3,<br/>
                        33010 Reana del Rojale (UD)<br/>
                        📞&nbsp;<Obfuscate tel="+393461880064" style={{ display: 'inline-block' }}/><br/>
                        📧&nbsp; <Obfuscate email="scuoladimusica@bandareana.it" style={{ display: 'inline-block' }}/>
                      </p>
                    </section>
                  </Col>
                </Row>

                
                
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export const pageQuery = graphql`
  query coursesPageQuery {
    allStrapiCourse(sort: {order: ASC, fields: name}) {
      nodes {
        name
        subtitle
        teachers {
          name
        }
      }
    }
  }
`;

export default Courses;
